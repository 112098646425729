import React from 'react';
import ProfileCard from './Profile/ProfileCard';
import SkillSection from './Skills/SkillSection';
import BioSection from './Profile/BioSection';
import QualificationSection from './Qaulification/QualificationSection';
import SideBottomBar from './SideBottomBar/SideBottomBar';

const HomePage = () => {
   return (
      <>
      <SideBottomBar />
      <div className="max-w-screen-md mx-auto">
         {/*================= Profile Details started from here================== */}
         <ProfileCard />
         <div className="px-5 pb-10">
            {/* ===========Bio Details Start======= */}
            <section className="mt-5 mb-6">
               <BioSection />
            </section>
            {/* ===============Bio Details END============= */}
            {/* ============== Button of Skills and Portfolio ============ */}
            <div className="flex items-center justify-center mb-5" id='skills'>
               <section className="px-2 sm:w-96 py-7 h-12 w-full bg-zinc-200/50 dark:bg-zinc-800/30 flex items-center gap-2 rounded-md relative">
                  <button className="h-10 w-[50%] rounded-md text-zinc-700 dark:text-zinc-300 font-medium btn-active">
                     Skills
                  </button>
                  <button className="h-10 w-[50%] rounded-md text-zinc-700 dark:text-zinc-300 font-medium">
                     Overall
                  </button>
               </section>
            </div>
            {/* ============= Button of Portfolio and Skill End ============ */}

            {/* ==============Button Content Start Here================ */}
            {/* ================Skill Everything Start================== */}
            <section className="flex flex-col gap-5">
               <SkillSection />
            </section>
            {/* ===============================Skill everything end====================== */}
            <div className="my-5" id='qualification'>
               <h1 className="text-xl text-center font-medium">
                  Qualification
               </h1>
            </div>
            <QualificationSection />
         </div>
      </div>
      </>
   );
};

export default HomePage;
