import React from 'react'

const BioSection = () => {
  return (
    <p className="font-medium text-xs text-justify dark:text-zinc-400">
    I'm{' '}
    <span className=" font-semibold text-sm dark:text-zinc-300">
       TANBIR
    </span>
    . I studied Computer Science. Now, extending skills beyond
    the{' '}
    <span className=" font-semibold text-sm dark:text-zinc-300">
       MERN{' '}
    </span>{' '}
    stack & Mordern{' '}
    <span className=" font-semibold text-sm dark:text-zinc-300">
       JavaScript
    </span>
    . I'm a big believer in learning by doing and having fun. I shift the focus from "How?" and "Why?" to "What?". I am committed to continuous
    learning and staying abreast of industry trends.{' '}
 </p>
  )
}

export default BioSection