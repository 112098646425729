import React, { useEffect, useState } from 'react'
import { UilMoon } from '@iconscout/react-unicons'
import { UilSun } from '@iconscout/react-unicons'

const ThemeSwitcher = () => {


    const [theme, setTheme] = useState(null);
  

    useEffect(() => {
        if(window.matchMedia("prefer-color-scheme: light").matches){
            setTheme('light')
        }else{
            setTheme('dark')
        }
    }, []);

    useEffect(()=>{
    if(theme === 'light'){
        document.documentElement.classList.remove('dark')
    }else{
        document.documentElement.classList.add('dark');
    }
    },[theme]);


    const handleThemeSwitch = () =>{
        setTheme(theme === 'light' ? 'dark' : 'light');
    }





  return (
    <button onClick={handleThemeSwitch} className='bg-zinc-50/30 dark:bg-zinc-800/30 rounded-full p-2 shadow-md'>
    {theme === 'light' ? <UilMoon className=" h-8 w-8 hover:text-sky-500 md:h-10 md:w-10"/> : <UilSun className="h-8 w-8 hover:text-yellow-500 md:h-10 md:w-10" /> }</button>
  );
}

export default ThemeSwitcher