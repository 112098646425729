import React from 'react'
import {ReactComponent as Github} from '../../assets/svg/Social/githubx.svg'
import {ReactComponent as Instagram} from '../../assets/svg/Social/instagram.svg'
import {ReactComponent as Twitter} from '../../assets/svg/Social/twitter.svg'
import {ReactComponent as Linkdedin} from '../../assets/svg/Social/linkedin.svg'


const SocialLogo = () => {
//Stored Social Data
const socialLogoData = [
    {
        id: 1,
        icon: <Github className=' fill-lime-600 hover:fill-lime-500'/>,
        link: "https://github.com/"
    },
    {
        id: 2,
        icon: <Twitter className=' fill-sky-600 hover:fill-sky-500'/>,
        link: "https://twitter.com/"
    },
    {
        id:3,
        icon: <Linkdedin className='fill-blue-600 hover:fill-blue-500'/>,
        link: "https://www.linkedin.com/in/"
    },
    {
        id: 4,
        icon: <Instagram className='fill-rose-600 hover:fill-rose-500'/>,
        link: "https://www.instagram.com/",
    }
];
  return (
    <>
    {
        socialLogoData.map(({id, icon, style, link})=>(
            <div key={id}>
                <a href={link} className="h-8 w-8 rounded-full flex items-center justify-center text-2xl transition-all bg-zinc-50/30 dark:bg-zinc-800/30 shadow">
                {icon}
                </a>
            </div>
            
        ))
    }
    </>
  )
}

export default SocialLogo