import React from 'react'
import avatarIMG from '../../assets/images/avatarImg.png'
import SocialLogo from './SocialLogo';
import verified from '../../assets/svg/experience/verified.svg'
import ThemeSwitcher from '../ThemeSwitcher';

const ProfileCard = () => {
  return (
    <div className=' dark:bg-zinc-900 text-center rounded-md flex flex-col items-center justify-center border-b-2 border-zinc-200/70 dark:border-zinc-800/70 pb-3' id='profile'>
        <div className='w-full h-32 bg-gradient-to-tr from-indigo-500 to-pink-500 flex items-start p-3'><ThemeSwitcher/></div>
        <div className=' absolute top-20' id='about'>
        <div className='h-4 w-4 bg-green-500 absolute top-0 right-0 rounded-full ring-2 ring-white'></div>
            <img src={avatarIMG} alt='' className=' h-24 rounded-xl ring-2 ring-white'/>
        </div>
        <div className='flex flex-col items-center relative mt-14'>
        <div className='text-xl font-semibold mt-2 flex'>
            Tanbir Hossen Sarkar
            <img src={verified} alt='' className='ml-2'/>
            </div>
        <div id='short' className='text-sm text-zinc-500 m-1'>MERN STACK DEVELOPER</div>
        <div id='describe' className='text-sm text-zinc-500 bg-zinc-200/50 dark:bg-zinc-800/30 py-1 px-3 rounded-xl'>Frontend • Backend</div>
        <div id='social' className='flex flex-row gap-3 m-2'><SocialLogo/></div>
    </div>
    </div>
  )
}

export default ProfileCard;