import React from 'react'
import cambrian from '../../assets/images/Education/Cambrianlogo.png'
import greenUni from '../../assets/images/Education/green-uni.png'

const Education = () => {
    const educationData = [
        {
            id: 1,
            degree: "Bachelor Computer Science",
            image: greenUni,
            institution: "Green University",
            duration: "Jan 2019 - July 2023"
        },
        {
            id: 2,
            degree: "HSC in Science",
            image: cambrian,
            institution: "Cambrian College",
            duration: "Jan 2016 - April 2018"
        }
    ]
  return (
    <>
        {educationData.map(({ id, degree, image, institution, duration }) => (
               
            <div
               key={id}
               className="h-12 w-auto border-2 rounded-md dark:border-zinc-800 flex items-center justify-between gap-3 py-7 px-3 truncate"
            >
               <div className=" flex items-center gap-4">
                  <img src={image} className="h-8 w-8 rounded-full " alt="" />
                  <div className=" flex flex-col gap-1">
                     <p className=" font-medium text-base">{degree}</p>
                     <span className={`text-xs font-medium text-zinc-500`}>
                        {institution} • {duration}
                     </span>
                     </div>
                  </div>
               </div>
         ))}
         </>
  )
}

export default Education