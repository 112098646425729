import React from 'react';
import android from '../../assets/svg/experience/android.svg';
import autocad from '../../assets/svg/experience/autocad.svg';
import camtasia from '../../assets/svg/experience/camtasia.svg';
import figma from '../../assets/svg/experience/figma.svg';
import git from '../../assets/svg/experience/git.svg';
import github from '../../assets/svg/experience/github.svg';
import photoshop from '../../assets/svg/experience/photoshop.svg';
import office from '../../assets/svg/experience/office.svg';
import npm from '../../assets/svg/experience/npm.svg';
import netlify from '../../assets/svg/experience/netlify.svg';
import vscode from '../../assets/svg/experience/vscode.svg';
import postman from '../../assets/svg/experience/postman.svg';

const MoreSkill = () => {
   // Stored MoreSkill Data
   const moreSkillData = [
      {
         id: 1,
         name: 'Git',
         icon: git,
         badge: 'Proficient',
         badgeClr: 'proficientColor', // src\index.css
      },
      {
         id: 2,
         name: 'Github',
         icon: github,
         badge: 'Proficient',
         badgeClr: 'proficientColor', // src\index.css
      },
      {
         id: 3,
         name: 'Postman',
         icon: postman,
         badge: 'Intermediate',
         badgeClr: 'intermediateColor', //src\index.css
      },
      {
         id: 4,
         name: 'NPM',
         icon: npm,
         badge: 'Proficient',
         badgeClr: 'proficientColor', // src\index.css
      },
      {
         id: 5,
         name: 'VSCode',
         icon: vscode,
         badge: 'Proficient',
         badgeClr: 'proficientColor', // src\index.css
      },
      {
         id: 6,
         name: 'Netlify',
         icon: netlify,
         badge: 'Proficient',
         badgeClr: 'proficientColor', // src\index.css
      },
      {
         id: 7,
         name: 'Android Studio',
         icon: android,
         badge: 'Intermediate',
         badgeClr: 'intermediateColor', // src\index.css
      },
      {
         id: 8,
         name: 'Figma',
         icon: figma,
         badge: 'Intermediate',
         badgeClr: 'intermediateColor', // src\index.css
      },
      {
         id: 9,
         name: 'Photoshop',
         icon: photoshop,
         badge: 'Proficient',
         badgeClr: 'proficientColor', // src\index.css
      },
      {
         id: 10,
         name: 'AutoCad',
         icon: autocad,
         badge: 'Intermediate',
         badgeClr: 'intermediateColor', // src\index.css
      },
      {
         id: 11,
         name: 'Office',
         icon: office,
         badge: 'Proficient',
         badgeClr: 'proficientColor', // src\index.css
      },
      {
         id: 12,
         name: 'Camtasia Studio',
         icon: camtasia,
         badge: 'Intermediate',
         badgeClr: 'intermediateColor', // src\index.css
      },
   ];

   return (
      <>
      {/* all css from ./skil.css */}
      
         {moreSkillData.map(({ id, icon, name, badge, badgeClr }) => (
            <div
               key={id}
               className="skills__card"
            >
               <div className="skill__items__flex">
                  <img src={icon} className="icon__size" alt="" />
                  <div className="skill__flex__col">
                     <p className="skill__title">{name}</p>
                     <span className={`${badgeClr} level_font`}>
                        {badge}
                     </span>
                  </div>
               </div>
            </div>
         ))}
      </>
   );
};

export default MoreSkill;
