import React, { useState } from 'react';
import Education from './Education';
import MoreSkill from '../Skills/MoreSkill';

const QualificationSection = () => {
    const [toggleQF, setToggleQF] = useState(1);
    function qualificationToggle(id){
        setToggleQF(id)
    }
   return (
      <div>
         <div className="flex items-center justify-center my-5">
            <section className="px-2 sm:w-96 py-7 h-12 w-full bg-zinc-200/50 dark:bg-zinc-800/30 flex items-center gap-2 rounded-md relative">
               <button className={toggleQF === 1 ? "h-10 w-[50%] rounded-md text-zinc-700 dark:text-zinc-300 font-medium btn-active" : "h-10 w-[50%] rounded-md text-zinc-700 dark:text-zinc-300 font-medium"} onClick={()=> qualificationToggle(1)}>
                  Education
               </button>
               <button className={toggleQF === 2 ? "h-10 w-[50%] rounded-md text-zinc-700 dark:text-zinc-300 font-medium btn-active" : "h-10 w-[50%] rounded-md text-zinc-700 dark:text-zinc-300 font-medium"} onClick={()=>qualificationToggle(2)}>
                  Works
               </button>
            </section>
         </div>
         <div className={toggleQF === 1 ? "block" : "hidden"}>
         <section className="flex flex-col gap-5">
            <figure
               className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-6"
               id="skillsSection"
            >
               <Education />
            </figure>
         </section>
         </div>
         <div className={toggleQF === 2 ? "block" : "hidden"}>
         <section className="flex flex-col gap-5">
            <figure
               className="grid grid-cols-1 md:grid-cols-3 gap-y-4 gap-x-6 duration-300"
               id="skillsSection"
            >
               <MoreSkill />
            </figure>
         </section>
         </div>
      </div>
   );
};

export default QualificationSection;
