import React, { useState } from 'react';
import { ReactComponent as HomeLine } from '../../assets/svg/sideBottomBar/home-line.svg';
import { ReactComponent as AboutUser } from '../../assets/svg/sideBottomBar/about-user.svg';
import { ReactComponent as Portfolio } from '../../assets/svg/sideBottomBar/portfolio-layer.svg';
import { ReactComponent as Skills } from '../../assets/svg/sideBottomBar/skills.svg';
import { ReactComponent as Qualification } from '../../assets/svg/sideBottomBar/qualification.svg';

import './BottomBar.css';

const BottomBar = () => {
   const bottomNavData = [
      {
         id: 1,
         name: 'About',
         link: '#about',
         icon: <AboutUser className="svg__customize" />,
      },
      {
         id: 2,
         name: 'Qualification',
         link: '#qualification',
         icon: <Qualification className="svg__customize" />,
      },
      {
         id: 3,
         name: 'Profile',
         link: '#profile',
         icon: <HomeLine className="svg__customize" />,
      },
      {
         id: 4,
         name: 'Portfolio',
         link: '#skill',
         icon: <Portfolio className="svg__customize" />,
      },
      {
         id: 5,
         name: 'Skills',
         link: '#skills',
         icon: <Skills className="svg__customize" />,
      },
   ];
   const [active, setActive] = useState(0);

   return (
      <div className="nav__menu md_nav_menu" id="nav-menu">
         {/* <!--=============== HEADER ===============--> */}
         <ul className="ul__nav__list ">
            {bottomNavData.map(({ id, name, link, icon }) => (
               <li
                  key={id}
                  className="nav__item list-none"
                  onClick={() => setActive(id)}
               >
                  <a
                     href={link}
                     className={`${
                        active === id
                           ? 'sider__bar__link sider__bar__link__translate group'
                           : 'sider__bar__link group'
                     }`}
                  >
                     <span>{icon}</span>
                     <span
                        className={`${
                           active === id
                              ? 'tooltip__name__mobile'
                              : 'tooltip__name'
                        }`}
                     >
                        {name}
                     </span>
                  </a>
               </li>
            ))}
         </ul>
      </div>
   );
};

export default BottomBar;
