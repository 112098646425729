import React from 'react';
import Node from '../../assets/svg/experience/node-js.svg';
import mongodb from '../../assets/svg/experience/mongodb.svg';
import express from '../../assets/svg/experience/express.svg';
import php from '../../assets/svg/experience/php.svg';
import java from '../../assets/svg/experience/java.svg';

const BackendSkill = () => {
   // Stored Backend Data
   const backendData = [
      {
         id: 1,
         name: 'Node.JS',
         icon: Node,
         badge: 'Intermediate',
         badgeClr: 'intermediateColor', // src\index.css
      },
      {
         id: 2,
         name: 'PHP',
         icon: php,
         badge: 'Average',
         badgeClr: 'averageColor', // src\index.css
      },
      {
         id: 3,
         name: 'MongoDB',
         icon: mongodb,
         badge: 'Intermediate',
         badgeClr: 'intermediateColor', // src\index.css
      },
      {
         id: 4,
         name: 'Express.JS',
         icon: express,
         badge: 'Intermediate',
         badgeClr: 'intermediateColor', // src\index.css
      },

      {
         id: 5,
         name: 'Java',
         icon: java,
         badge: 'Average',
         badgeClr: 'averageColor', // src\index.css
      },
   ];

   return (
      <>
      {/* all css from ./skil.css */}
      
         {backendData.map(({ id, icon, name, badge, badgeClr }) => (
            <div
               key={id}
               className="skills__card"
            >
               <div className="skill__items__flex">
                  <img src={icon} className="icon__size" alt="" />
                  <div className="skill__flex__col">
                     <p className="skill__tile">{name}</p>
                     <span className={`${badgeClr} level_font`}>
                        {badge}
                     </span>
                  </div>
               </div>
            </div>
         ))}
      </>
   );
};

export default BackendSkill;
