import React from 'react';
import JavaScript from '../../assets/svg/experience/javascript.svg';
import Tailwind from '../../assets/svg/experience/tailwindcss.svg';
import ReactJS from '../../assets/svg/experience/react.svg';
import Html from '../../assets/svg/experience/html.svg';
import Css from '../../assets/svg/experience/css.svg';
import Bootstrap from '../../assets/svg/experience/bootstrap.svg';

const FrontendSkill = () => {
   // Stored Frontend Data
   const FrontendData = [
      {
         id: 1,
         name: 'JavaScript',
         icon: JavaScript,
         badge: 'Intermediate',
         badgeClr: 'intermediateColor', // src\index.css
      },
      {
         id: 2,
         name: 'Tailwind Css',
         icon: Tailwind,
         badge: 'Proficient',
         badgeClr: 'proficientColor', // src\index.css
      },
      {
         id: 3,
         name: 'React',
         icon: ReactJS,
         badge: 'Intermediate',
         badgeClr: 'intermediateColor', // src\index.css
      },
      {
         id: 4,
         name: 'Html',
         icon: Html,
         badge: 'Proficient',
         badgeClr: 'proficientColor', // src\index.css
      },
      {
         id: 5,
         name: 'Css',
         icon: Css,
         badge: 'Proficient',
         badgeClr: 'proficientColor', // src\index.css
      },
      {
         id: 6,
         name: 'Bootstrap',
         icon: Bootstrap,
         badge: 'Proficient',
         badgeClr: 'proficientColor', // src\index.css
      },
   ];
   return (
      <>
      {/* all css from ./skil.css */}
         {FrontendData.map(({ id, icon, name, badge, badgeClr }) => (
            <div
               key={id}
               className="skills__card"
            >
               <div className="skill__items__flex">
                  <img src={icon} className="icon__size" alt="" />
                  <div className="skill__flex__col">
                     <p className="skill__tile">{name}</p>
                     <span className={`${badgeClr} level_font`}>
                        {badge}
                     </span>
                  </div>
               </div>
            </div>
         ))}
      </>
   );
};

export default FrontendSkill;
