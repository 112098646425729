import HomePage from "./components/HomePage";

function App() {
  return (
    <div className='h-max relative bg-zinc-50 text-zinc-900 dark:bg-zinc-900 dark:text-zinc-50 duration-300'>
      <HomePage/>
    </div>
  );
}

export default App;
