import React from 'react'
import FrontendSkill from './FrontendSkill'
import BackendSkill from './BackendSkill'
import MoreSkill from './MoreSkill'
import "./skills.css"

const SkillSection = () => {
  return (
    <>
    {/* all css from ./skil.css */}

    
    {/*============Title Of Experience==================*/}
    <h1 className="skill_section_tile" >
       Frontend Developement
    </h1>
    <figure
       className="skill_figure_grid"
       id="skillsSection"
    >
       {/* ===========List from FrontendSill.jsx============== */}
       <FrontendSkill />
    </figure>
    <h1 className="skill_section_tile">
       Backend Developement
    </h1>
    <figure
       className="skill_figure_grid"
       id="backendSkillsSection"
    >
       {/* ===========List from BackendSill.jsx============== */}
       <BackendSkill />
    </figure>
    <h1 className="skill_section_tile">More Tools</h1>
    <figure
       className="skill_figure_grid"
       id="otherToolsSection"
    >
       {/* ===========List from MoreSill.jsx============== */}
       <MoreSkill />
    </figure>
    </>
  )
}

export default SkillSection